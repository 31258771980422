.App {
  width: 100vw;
  min-height: 100vh;
  background-image: url("./resources/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#privacy-policy {
  scroll-margin-top: 80px;
}
